import {React, useState} from 'react';
import TextField from '@material-ui/core/TextField';
import moment from "moment/moment";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import {Button} from "@mui/material";

const categories = [
    'overallRange',
    'ageRange0To6',
    'ageRange7To9',
    'ageRange10To12',
    'ageRange13To15',
 //   'ageRange16AndAbove',
    'ageRange16To19',
    'ageRange20To25',
    'ageRange26To31',
    'ageRange32To36',
    'ageRange37AndAbove',
    'bestFemaleRanking',
    'bestFirstTimerRanking'
];
const ResultAnalyzerPage =()  =>{
    const [malaysianOnly, setMalaysianOnly] = useState(false);
    const [combinedRound, setcombinedRound] = useState(false);
    const [competitionData, setcompetitionData] = useState(null);
    const handleMalaysianCheckboxChange = (event) =>{
        setMalaysianOnly(event.target.checked);

    }
    const handleCombinedResultCheckboxChange = (event) =>{
        setcombinedRound(event.target.checked);

    }
    const handleFileUpload =async (event) => {
        const file = event.target.files[0];
        const formData = new FormData();
        formData.append('file', file);
//load and read the file from txt to json data to a variable
        const reader = new FileReader();
        reader.onload = async (e) => {
            const text = (e.target.result);
            const competitionDataJson = JSON.parse(text);
            setcompetitionData(competitionDataJson);
        };
       reader.readAsText(file);

    };
    const handleGenerateButtonClick =async () => {
        if (!competitionData) {
            return;
        }
        console.log(competitionData)
      await  analayzeData(competitionData,malaysianOnly,combinedRound);
    }
    return (
        <div>
            <form >
                <TextField onChange={handleFileUpload} type="file" />
                <br/>
                <FormControlLabel checked={malaysianOnly} key="1" name="malaysianOnly" onChange={handleMalaysianCheckboxChange} control={<Checkbox />} label= "Malaysian Ranking Only" />
                <FormControlLabel checked={combinedRound} key="2" name="combinedRound" onChange={handleCombinedResultCheckboxChange} control={<Checkbox />} label= "Combined Round" disabled={true}/>

            </form>
            <Button variant="contained" onClick={handleGenerateButtonClick}>Generate Result</Button>
        </div>

    );
}

const analayzeData =async (data,malaysianOnly,combinedRound) => {
    //loop every data.persons, save into a hash table and use id as key
    const personData = data.persons;
    const personHashTable = {};
    const eventList = [];
  const  resultHashTable = {};
    personData.forEach((person) => {
        //filter out the person if they are not malaysian
        if (malaysianOnly && person.countryId !== 'MY') {
            return;
        }

        personHashTable[person.id] = person;
    });


    let eventData = data.events;
    if (combinedRound){
        const combinedResult = [];
        await Promise.all(
            eventData.map(
                async (event) => {
                    const eventCombinedResult = new Map();
                    const rankByBestSingle = event.rounds[0].formatId !== 'a';
                    //loop event round
                    for (let i = 0; i < event.rounds.length; i++) {

                        const round = event.rounds[i];
                        let roundResult = round.results;

                        //loop each participant
                        for (let i = 0; i < roundResult.length; i++) {
                            const resultRow = roundResult[i];
                            if (eventCombinedResult.has(resultRow.personId)){
                              // get the row with best result
                                if (rankByBestSingle){
                                    if (resultRow.best < 0){
                                        continue;
                                    }
                                    //use single
                                    const previousSingle = resultRow.best;
                                    const currentSingle = eventCombinedResult.get(resultRow.personId).best;
                                    if (currentSingle > previousSingle){
                                        eventCombinedResult.set(resultRow.personId,resultRow);
                                    }
                                }
                                else {
                                    if (resultRow.average < 0){
                                        continue;
                                    }
                                    //use average
                                    const previousAverage = resultRow.average;
                                    const currentAverage = eventCombinedResult.get(resultRow.personId).average;
                                    if (currentAverage > previousAverage){
                                        eventCombinedResult.set(resultRow.personId,resultRow);
                                    }
                                }
                            }
                            else {

                                if (rankByBestSingle){
                                    if (resultRow.best > 0){
                                        eventCombinedResult.set(resultRow.personId,resultRow);
                                    }
                                }
                                else{
                                    if (resultRow.average > 0){
                                        eventCombinedResult.set(resultRow.personId,resultRow);
                                    }
                                }
                            }
                        }

                    }
                    // add back the result here, convert back from hash table to array
                    let combinedEventResultArray = [];
                    eventCombinedResult.forEach((value, key) => {
                        combinedEventResultArray.push(value);
                    });
                        if (rankByBestSingle){
                    // sort by best
                            combinedEventResultArray.sort((a, b) => (a.best > b.best) ? 1 : -1);
                        }
                        else{
                            //sort by average
                            combinedEventResultArray.sort((a, b) => (a.average > b.average) ? 1 : -1);
                        }
                    for (let i = 0; i < combinedEventResultArray.length; i++) {
                        combinedEventResultArray[i].position = i+1;
                    }
                    combinedResult.push({
                     ...event,
                        rounds: [{
                         roundId:"1",
                            formatId:rankByBestSingle?'a':'1',
                            results:combinedEventResultArray
                        }]
                    });
                }
            )
        )
        data.events = combinedResult;
    }
     eventData = data.events;
    const resultSummary = [];
    await Promise.all(
        eventData.map(
            async (event) => {
                const eventResult = [];

                //loop event round
                for (let i = 0; i < event.rounds.length; i++) {
                    const round = event.rounds[i];
                    let roundResult = round.results;

                    //filter malaysian only if checkbox is checked
                    if (malaysianOnly){
                        roundResult = roundResult.filter((result) => {
                            return (personHashTable.hasOwnProperty(result.personId) && personHashTable[result.personId].countryId === 'MY');
                        });
                    }
                    //sort the array by lastRoundResult.position
                    roundResult.sort((a, b) => (a.position > b.position) ? 1 : -1);
                    //resort the postition again
                    for (let i = 0; i < roundResult.length; i++) {
                        roundResult[i].position = i+1;
                    }
                    const overallRange = filterRankingByAge(roundResult, 0, 999, personHashTable);
                    const ageRange0To6 = filterRankingByAge(roundResult, 0, 6, personHashTable);
                    const ageRange7To9 = filterRankingByAge(roundResult, 7, 9, personHashTable);
                    const ageRange10To12 = filterRankingByAge(roundResult, 10, 12, personHashTable);
                    const ageRange13To15 = filterRankingByAge(roundResult, 13, 15, personHashTable);
                    const ageRange16AndAbove = filterRankingByAge(roundResult, 16, 999, personHashTable);
                    const ageRange16To19 = filterRankingByAge(roundResult, 16, 19, personHashTable);
                    const ageRange20To25 = filterRankingByAge(roundResult, 20, 25, personHashTable);
                    const ageRange26To31 = filterRankingByAge(roundResult, 26, 31, personHashTable);
                    const ageRange32To36 = filterRankingByAge(roundResult, 32, 36, personHashTable);
                    const ageRange37AndAbove = filterRankingByAge(roundResult, 37, 999, personHashTable);
                    const bestFemaleRanking = filterRankingByGender(roundResult, 'f',personHashTable);
                    const bestFirstTimerRanking = filterRankingByFirstTimer(roundResult,personHashTable);
                    const result = {
                        event: event.eventId,
                        round: round.roundId,
                        overallRange: overallRange,
                        ageRange0To6: ageRange0To6,
                        ageRange7To9: ageRange7To9,
                        ageRange10To12: ageRange10To12,
                        ageRange13To15: ageRange13To15,
                        ageRange16AndAbove: ageRange16AndAbove,
                        ageRange16To19: ageRange16To19,
                        ageRange20To25: ageRange20To25,
                        ageRange26To31: ageRange26To31,
                        ageRange32To36: ageRange32To36,
                        ageRange37AndAbove: ageRange37AndAbove,
                        bestFemaleRanking: bestFemaleRanking,
                        bestFirstTimerRanking: bestFirstTimerRanking,
                    };
                    eventResult.push(result);
                    //save roundResult to person
                 await  Promise.all( roundResult.map((result) => {
                    personHashTable[result.personId][`${event.eventId}${round.roundId}`] = result.position;
                }));

                eventList.push({eventName:`${event.eventId}${round.roundId}`,totalRanking:roundResult.length})
            }
                resultSummary.push(eventResult);
            }
        )
    )

   const bestAllRounder =await computeBestAllRounder(personHashTable,eventList);
    //export the data to xlsx
    const XLSX = require('xlsx');
    const wb = XLSX.utils.book_new();
    //json to sheet with fix header
    const allrounderSheet = XLSX.utils.json_to_sheet(bestAllRounder);
    XLSX.utils.book_append_sheet(wb, allrounderSheet, "Best All Rounder");
    // loop every event
   await resultSummary.forEach((eventResult) => {
        const resultArray = [];
        eventResult.forEach((roundResult) => {

            //loop categories
        categories.forEach((category) => {
                //loop every ranking
          const result = {
                event: roundResult.event,
                round: roundResult.round,
                category: category,
                first: roundResult[category][0] ? personHashTable[roundResult[category][0].personId].name : '',
                second: roundResult[category][1] ? personHashTable[roundResult[category][1].personId].name : '',
              third: roundResult[category][2] ? personHashTable[roundResult[category][2].personId].name : '',
          }

 resultArray.push(result);});

        });
        try{
            const eventSheet = XLSX.utils.json_to_sheet(resultArray);
            XLSX.utils.book_append_sheet(wb, eventSheet, eventResult[0].event);
        }
        catch (e) {
            console.log(e)
        }


    });
let fileName = malaysianOnly ? "MalaysianOnlyResult" : "Result";
if (combinedRound){
    fileName += "_CombinedRound";
}
fileName += `_${data.competitionId}_${moment().format('YYYYMMDDHHmmss')}`;
fileName += ".xlsx";
    XLSX.writeFile(wb, fileName);
    //download the file
    const element = document.createElement("a");
    const file = new Blob([XLSX.write(wb, {bookType:'xlsx', type:'buffer'})], {type: 'application/octet-stream'});
    element.href = URL.createObjectURL(file);
    element.download = "Best All Rounder.xlsx";
    document.body.appendChild(element); // Required for this to work in FireFox



};
const computeBestAllRounder = async(personHashTable,eventList) => {
    await Promise.all(Object.keys(personHashTable).map((personId) => {
        const person = personHashTable[personId];
        let totalRanking = 0;
        eventList.forEach((event) => {
            if (person[event.eventName] !== undefined) {
                totalRanking += person[event.eventName];
            }
            else{
                totalRanking += event.totalRanking + 1;
                person[event.eventName] = event.totalRanking + 1;
            }
        });
        personHashTable[personId].totalRanking = totalRanking;
        const currentYear = moment().year();
        const birthYear = moment(personHashTable[personId].dob).year();
        personHashTable[personId].age = currentYear - birthYear;
    }));
    //sort by totalRanking
    const personArray = Object.keys(personHashTable).map((personId) => {
        return personHashTable[personId];
    });
    personArray.sort((a, b) => (a.totalRanking > b.totalRanking) ? 1 : -1);
    return personArray;

}
const filterRankingByAge = (ranking, startAge, endAge, personHashTable) => {
    const currentYear = moment().year();
    const filteredRanking = ranking.filter((person) => {
        const birthYear = moment(personHashTable[person.personId].dob).year();
        const age = currentYear - birthYear;
        return age >= startAge && age <= endAge;
    });
    //only get first 3 results
    return filteredRanking.slice(0, 3);
};

const filterRankingByGender = (ranking,gender,personHashTable) => {
    const filteredRanking = ranking.filter((person) => {
        return personHashTable[person.personId].gender === gender
    });
    return filteredRanking.slice(0, 3);
}

const filterRankingByFirstTimer = (ranking,personHashTable) => {
    const filteredRanking = ranking.filter((person) => {
        return personHashTable[person.personId].wcaId === '' || personHashTable[person.personId].wcaId.includes(moment().year().toString());
    });
    return filteredRanking.slice(0, 3);
}

export default ResultAnalyzerPage;